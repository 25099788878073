// Importing libraries
import React from 'react';
import { Route, Switch } from 'react-router-dom'

// Importing style and images
import logo from './logo.svg';
import './designs/index.scss'

// Importing constants
import CONSTANTS from './constants/'

// Importing Full Views
import Home from './views/Home'
import Details from './views/Details'

import Navbar from './components/common/Navbar'
import ProductList from './components/common/ProductLIst';
import NotFound from './components/common/NotFound';


function App() {
	return (
		<div className="container">
			<Navbar />
			<div className="product-list">
				<Switch>
					<Route exact path="/" />
					<Route component={ProductList} path="/items" />
					<Route component={Details} exact path="/item/:id" />
					<Route component={NotFound} />
				</Switch>
			</div>

		</div>

	);
}

export default App;
