import React from 'react'
import ProductList from '../components/common/ProductLIst'


function Home() {
    return (
        <div>
            <ProductList />
        </div>
    )
}

export default Home
