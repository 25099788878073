import React from 'react'

function ProductLIst() {
    return (
        <div>
            Esto es product list
        </div>
    )
}

export default ProductLIst
