import React from 'react'
import { Link } from 'react-router-dom'

function NotFound() {
  return (
    <div className="text-center">
      404, Pagina no encontrada
      <br />
      <Link to="/">Ir a home</Link>
    </div>
  )
}

export default NotFound
