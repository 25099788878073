import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'


const Navbar = () => {
    return (
        <div className="navbar_top">
            <Link to="/" className="logo" />
            <form className="input_search" >
                <input
                    type="text"
                    className="navbar_input"
                    placeholder="Buscar productos, marcas y mas..."
                    aria-label="Buscar productos, marcas y mas..."
                />
                <button className="btn_search"  >
                    <i className="icon_search" />
                </button>
            </form>
        </div>
    )
}

export default Navbar