import React from 'react'
import Navbar from '../components/common/Navbar'
import ProductDetails from '../components/common/ProductDetails'
import { Link } from 'react-router-dom'

function Details() {
    return (
        <div>
            <Link to="/">Hacia home</Link>
            <ProductDetails></ProductDetails>
        </div>
    )
}

export default Details
